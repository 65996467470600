import $ from 'jquery'

// To enable tooltip in all pages
const MekariTooltip = () => {
  $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' })
}

$(document).ready(function () {
  MekariTooltip()
})

export default MekariTooltip
