import $ from 'jquery'

// Create js for init dropdown
const dropdownInit = () => {
  // find dropdown with pill class
  const $wrapperDropdownPill = $('.dropdown-with-pill');

  // find dropdown with select class
  const $wrapperDropdownSelect = $('.dropdown-with-select');

  // find dropdown with search class
  const $dropdownWithSearch = $('.dropdown-action--search');

  // Init first load. If $wrapperDropdownPill exist, display all selected option in pill
  $wrapperDropdownPill.each(function(){
    let $selectedOptions = $(this).find('.dropdown-selected-list');
    const $menuList = $(this).find('.dropdown-menu-list');
    const $selectNameHidden = $menuList.attr('data-select-name');
    const $arrayListSelected = $(this).find('.dropdown-menu .is-selected').map(function(){return $(this).attr("data-val");}).get();
    if (!$selectedOptions.length) {
      $(this).append(`<div class='dropdown-selected-list'></div>`);
      $selectedOptions = $(this).find('.dropdown-selected-list');
    }

    // insert all selected options to pill
    for(let item of $arrayListSelected) {
      const $itemId = $(this).find(".dropdown-item[data-val='"+item+"']").attr('data-id');
      $selectedOptions.append(`<span class='badge badge-smoke' data-id='${$itemId}' data-val='${item}'>${item} <i class='ic ic-reset'></i></span><input type='hidden' value='${$itemId}' name='${$selectNameHidden}'>`);
    }

    // find all dropdown pill and set listener for delete
    const $pillList = $('.dropdown-with-pill .badge .ic');
    removePill($pillList);

    // listen to DomInserted in dropdown-menu-list
    $menuList.bind("DOMNodeInserted",function(e){
      if (e.target !== $menuList[0]) {
        e.target.addEventListener('click', activateThisItem);
      }
    });    
  });

  // Init first load. If $wrapperDropdownSelect exist, display selected option & append input hidden
  $wrapperDropdownSelect.each(function(){
    const $menuList = $(this).find('.dropdown-menu-list');
    const $selectNameHidden = $menuList.attr('data-select-name');

    const $optionSelected = $(this).find('.dropdown-menu .is-selected');
    const $itemId = $optionSelected.attr('data-id');
    const $itemVal = $optionSelected.text();

    // display selected option & append input hidden
    if ($itemVal){
      $(this).find('.dropdown-toggle').html($itemVal);
      $(this).append(`<div class='dropdown-selected-list'><input type='hidden' value='${$itemId}' name='${$selectNameHidden}'></div>`);
    } else {
      $(this).append(`<div class='dropdown-selected-list'><input type='hidden' value='' name='${$selectNameHidden}'></div>`);
    }
  });

  // find all options
  const $dropdownPillItems = $('.dropdown-with-pill .dropdown-item');
  const $dropdownSelectItems = $('.dropdown-with-select .dropdown-item');

  // Add 'is-selected' in dropdown menu when option is selected
  $dropdownPillItems.click(activateThisItem);
  $dropdownSelectItems.click(activateThisItem);

  // Init search dropdown. If $dropdownWithSearch exist, listen when keydown
  $dropdownWithSearch.each(function(){
    const $inputSearch = $(this).find("input[type='text']");
    const $dropdownMenu = $inputSearch.closest('.dropdown-menu');
    
    $inputSearch.keyup(function(e) {
      const $arrayListOptions = $dropdownMenu.find('.dropdown-menu-list .dropdown-item').not('.text-link');
      const $searchText = e.currentTarget.value.toLocaleLowerCase();
      $arrayListOptions.each(function() {
        if(!$(this).text().toLocaleLowerCase().includes($searchText)) {
          $(this).addClass('d-none');
        } else {
          $(this).removeClass('d-none');
        }
      });

      // find all option with hiden ('d-none') class
      const $hiddenOpt = $dropdownMenu.find('.dropdown-menu-list .dropdown-item.d-none').not('.text-link');
      const $notFound = $dropdownMenu.find('span.dropdown-not-found');
      if ($hiddenOpt.length === $arrayListOptions.length) {
        if ($notFound.length) {
          $notFound.removeClass('d-none');
        } else {
          $dropdownMenu.append(`<span class="dropdown-not-found">No data found</span>`);
        }
      } else {
        $notFound.addClass('d-none');
      }
    });
  });
}

const removePill = (item) => {
  // remove pill and class is-selected in dropdown menu when button 'x' is clicked
  item.click(function(){
    const $badge = $(this).closest('.badge');
    const $val = $badge.attr('data-val');
    const $itemId = $badge.attr('data-id');
    $badge.closest('.dropdown-selected-list').find("input[value='"+$itemId+"']").remove();
    $badge.closest('.dropdown-with-pill')
            .find(".is-selected[data-val='"+$val+"']")
            .removeClass('is-selected');
    $badge.remove();
  });
}

const activateThisItem = () => {
  const $this = $(event.currentTarget);
  let $dropdownType;
  if ($this.closest('.dropdown').hasClass('dropdown-with-select')){
    $dropdownType = '.dropdown-with-select';
  } else if ($this.closest('.dropdown').hasClass('dropdown-with-pill')) {
    $dropdownType = '.dropdown-with-pill';
  }
  if (!$this.hasClass('dropdown-action')){
    const $wrapperDropdown = $this.closest($dropdownType);
    let $selectedOptions = $wrapperDropdown.find('.dropdown-selected-list');

    // remove selected class in all options
    if($dropdownType == '.dropdown-with-select'){
      $wrapperDropdown.find('.dropdown-item').removeClass('is-selected');
    }

    // add selected class
    $this.addClass('is-selected');
    const $itemId = $this.attr('data-id');
    const $selectNameHidden = $wrapperDropdown.find('.dropdown-menu-list').attr('data-select-name');

    if($dropdownType == '.dropdown-with-pill'){
      // adding pill
      const $val = $this.attr('data-val');
      $selectedOptions.append(`<span class='badge badge-smoke' data-id='${$itemId}' data-val='${$val}'>${$val}<i class='ic ic-reset'></i></span> <input type='hidden' value='${$itemId}' name='${$selectNameHidden}'>`);
  
      const $pill = $selectedOptions.find(`.badge[data-val='${$val}'] .ic`);
      removePill($pill);
    } else if($dropdownType == '.dropdown-with-select'){
      // adding selected value & input hidden
      $wrapperDropdown.find('.dropdown-toggle').addClass('selected').html($this.text());
      $selectedOptions.html(`<input type='hidden' value='${$itemId}' name='${$selectNameHidden}'>`);
    }
  };
}

$(document).ready(function () {
  dropdownInit();
})

export default dropdownInit