import $ from 'jquery'

const MekariForm = () => {
  checkAll();
}

const checkAll = () => {
  // find all class name checkallbox
  const $checkallWrapper = $('.checkallbox-wrapper');

  $checkallWrapper.each(function(){
    const $datacheckall = $(this).attr('data-checkall');
    const $parentCheckbox = $(this).find(`.checkallbox-parent[data-checkall = '${$datacheckall}']`);
    const $arrayListCheckbox = $(this)
                              .find(`input[type='checkbox'][data-checkall = '${$datacheckall}']`)
                              .not('.checkallbox-parent');

    // update check all status (init)
    updateCheckAll($parentCheckbox, $arrayListCheckbox);

    // call function for trigered when parent is change
    activateParentCheckAll($parentCheckbox, $arrayListCheckbox);

    // call function for trigered when child is change
    activateChildCheckAll($parentCheckbox, $arrayListCheckbox);
  });
}

const updateCheckAll = ($parentCb, $childCb) => {
  const $totalChildCb = $childCb.length;
  const $totalChildChecked = $childCb
                            .filter(function(){return $(this)
                            .is(':checked') === true;}).length;
  if( $totalChildChecked === $totalChildCb && $totalChildCb > 0 ) {
    $parentCb.prop('checked', true);
  } else {
    $parentCb.prop('checked', false);
  }
}

const activateParentCheckAll = ($parentCb, $childCb) => {
  $parentCb.change(function(){
    if($(this).is(':checked')){
      $childCb.prop('checked', true);
    } else {
      $childCb.prop('checked', false);
    }
  })
}

const activateChildCheckAll = ($parentCb, $childCb) => {
  $childCb.change(function(){
    updateCheckAll($parentCb, $childCb);
  });
}

$(document).ready(function () {
  MekariForm()
})

export default MekariForm
